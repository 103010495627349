const IS_ANDROID = /android/i.test(navigator.userAgent);

// https://stackoverflow.com/a/9039885/1314762
const isIOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

const IS_SAFARI = /Safari\//.test(navigator.userAgent);
const IS_FIREFOX = /firefox/i.test(navigator.userAgent);
const IS_IOS_CHROME = isIOS() && /CriOS\//.test(navigator.userAgent);
const IS_IOS_SAFARI = isIOS() && IS_SAFARI;

const SUPPORTS_QUICKLOOK = (() => {
  const anchor = document.createElement('a');

  // The try catch is because of a jest bug https://github.com/facebook/jest/issues/9631
  try {
    return anchor.relList?.supports?.('ar');
  } catch (e) {
    return undefined;
  }
})();

export const IS_QUICK_LOOK = (IS_IOS_CHROME || IS_IOS_SAFARI) && SUPPORTS_QUICKLOOK;

export const IS_SCREEN_VIEWER = IS_ANDROID && !IS_FIREFOX;
