import { getVariantElement, getVariantWrapperElement } from '../template/variants';
import { EVENTS } from '../utils/analytics';
import { prepareTextures } from './Variants.helpers';

export class Variants {
  constructor(rootElem, viewer, analytics, onVariantChangeCB) {
    this._rootElem = rootElem;
    this._viewer = viewer;
    this._analytics = analytics;
    this.onVariantChangeCB = onVariantChangeCB;
    this._activeVariantId = 'initial';
    this._variants = [];
  }

  getActiveVariantData = () => {
    return this._variants.find((variant) => variant.id === this._activeVariantId);
  };

  clearVariants = () => {
    this._variants = [];
    const variantsElement = this._rootElem.getElementById('variants');
    variantsElement.innerHTML = '';
  };

  _applyVariant = (cloudinary, variant) => {
    const { meshName, textures, isDefault } = variant;
    // if default and no publicId, reset the texture to the default, instead of loading from a file
    if (isDefault && !textures?.map) {
      this._viewer.resetTextures();
    } else {
      // Load texture from file
      const normalizedTextures = prepareTextures(textures, cloudinary);
      this._viewer.loadTextures(normalizedTextures, meshName);
    }
  };

  addVariants = (cloudinary, variants) => {
    this._variants = Array.isArray(variants) ? variants : [];
    const variantsElement = this._rootElem.getElementById('variants');
    variantsElement.appendChild(getVariantWrapperElement().cloneNode(true));
    this._variants.forEach((variant) => {
      const { id, color, colorB, name, isDefault } = variant;
      const variantElem = getVariantElement(name, color, colorB);
      const button = variantElem.querySelector('button');
      if (isDefault) {
        this._applyVariant(cloudinary, variant); // initial load of textures, in case default has textures as well
        button.setAttribute('data-active', 'true');
      }

      variantElem.querySelector('button').addEventListener('click', () => {
        // Clear other buttons selected state, and add it to the clicked button
        variantsElement.querySelectorAll('button').forEach((button) => {
          button.setAttribute('data-active', '');
        });
        button.setAttribute('data-active', 'true');

        this._analytics(EVENTS.VARIANT_CHANGED, `Changed variant to ${name}`, { name });
        this._activeVariantId = id;
        this.onVariantChangeCB();

        this._applyVariant(cloudinary, variant);
      });
      variantsElement.querySelector('#variants-wrapper').appendChild(variantElem);
    });
  };
}
