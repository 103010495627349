import { FORMAT } from './common/consts';
import { IS_QUICK_LOOK, IS_SCREEN_VIEWER } from './common/helpers';
import { MobileArLinkGeneratorProps, AndroidMobileArLinkProps, SrcProps } from './mobileArLinkGenerator.types';
import { modelWithVariantImageSwap } from './modelWithVariantImageSwap';

const getMobileDeviceArSrc = ({ cloudName, publicId, variant, device, optimize, quality }: SrcProps) => {
  const format = FORMAT[device];
  return modelWithVariantImageSwap(cloudName, publicId, format, variant, optimize, quality);
};

const getAndroidMobileArLink = ({ src, fallbackUrl, placement }: AndroidMobileArLinkProps) => {
  let href = `intent://arvr.google.com/scene-viewer/1.0?file=${src}`;
  href += `&enable_vertical_placement=${placement === 'wall' ? 'true' : 'false'}`;
  href += '&mode=ar_only';
  href += '&resizable=true';
  href += '#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;';
  href += `S.browser_fallback_url=${encodeURIComponent(fallbackUrl)};`;
  href += 'end;';
  return href;
};

export const mobileArLinkGenerator = ({ cloudName, publicId, variant, fallbackUrl, placement, optimize, quality }: MobileArLinkGeneratorProps) => {
  if (IS_QUICK_LOOK) {
    const iosSrc = getMobileDeviceArSrc({ cloudName, publicId, variant, optimize, quality, device: 'ios' });
    return `${iosSrc}#`;
  } else if (IS_SCREEN_VIEWER) {
    const androidSrc = getMobileDeviceArSrc({ cloudName, publicId, variant, optimize, quality, device: 'android' });
    return getAndroidMobileArLink({ src: androidSrc, fallbackUrl, placement });
  } else {
    return null;
  }
};

export const isDeviceSupportsAr = IS_QUICK_LOOK || IS_SCREEN_VIEWER;
