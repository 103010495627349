import { Cloudinary } from '@cloudinary/url-gen';
import { AssetData, ContextData } from './types';

export const loadModelConfigurationFromContext = (cloudName: string, publicId: string, shouldLoad: boolean) => {
  const cloudinary = new Cloudinary({
    cloud: {
      cloudName,
    },
  });
  const json = cloudinary.image(`3d-info-${publicId}.json`).setDeliveryType('list').toURL();
  return new Promise<ContextData>((resolve, reject) => {
    // If the user decides not to use variants, resolve the promise with no data
    if (!shouldLoad) {
      resolve({});
      return;
    }
    fetch(json)
      .then(function (response) {
        if (!response.ok) {
          reject(`HTTP error! status: ${response.status}`);
          return;
        }
        return response.json();
      })
      .then(function (data: AssetData) {
        const configurationRaw = data?.resources?.[0]?.context?.custom?.['3d-info'];
        if (!configurationRaw) {
          reject('no-data-found');
        } else {
          resolve(JSON.parse(configurationRaw) as ContextData);
        }
      })
      .catch(() => {
        reject('Request failed');
      });
  });
};
