import throttle from '../../misc/throttle';
import { getContainerSizeDeclaration } from './helpers';

const MLS = 16; // 1000ms / 60fps ≈ 16 milliseconds per frame

export class Resize {
  constructor(rootElement) {
    this._rootElement = rootElement;

    this.onResize();
    this.throttledOnResize = throttle(this.onResize, MLS);
    window.addEventListener('resize', this.throttledOnResize);
  }

  detachResize = () => {
    window.removeEventListener('resize', this.throttledOnResize);
  };

  onResize = () => {
    const containerElement = this._rootElement.querySelector('#container');
    const size = getContainerSizeDeclaration(this._rootElement);

    containerElement.setAttribute('data-size', size);
  };
}
