import { Cloudinary } from '@cloudinary/url-gen';
import { Texture, Variant } from './types';
import { E_SWAP_IMAGE } from './common/consts';

export const swapImageTransformation = (index: string | number, url: string) => {
  const normalizeUrl = url.startsWith('http') ? `fetch:${btoa(url)}` : url.replace(/\//gi, ':');
  return `$s_ref:!${normalizeUrl}!/${E_SWAP_IMAGE}:image_$s;index_${index}`;
};

export const modelWithVariantImageSwap = (
  cloudName: string,
  publicId: string,
  format: string,
  variant?: Variant,
  optimize?: boolean,
  quality?: string
) => {
  const cloudinary = new Cloudinary({
    cloud: {
      cloudName,
    },
  });
  const image = cloudinary.image(`${publicId}.${format}`);
  if (variant?.textures) {
    for (const key of Object.keys(variant.textures)) {
      const texture = variant.textures[key as Texture];
      if (texture) {
        const { index, url } = texture;
        if (url) {
          image.addTransformation(swapImageTransformation(index, url));
        }
      }
    }
  }
  if (optimize && quality) {
    image.addTransformation(`e_decimate:${quality}`);
  }
  return image.toURL().replace(/\?(?:.(?!\?))+$/, '');
};
