import { Scene, LoadingManager, REVISION } from 'three';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader.js';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';

import { createLabelRenderer, createRenderer } from './systems/Renderer';
import { Resizer } from './systems/Resizer';
import { createCamera } from './components/Camera';
import { Controls } from './components/Controls';
import { Lights } from './components/Lights';
import { Animate } from './systems/Animate';
import { extractData, repositionObjectAndCameraToCenter, traverseObjectMaterials } from './Viewer.helpers';
import { HDRLoader } from './systems/HDRLoader';
import { loadTexture } from './systems/TextureLoader';
import { Hotspots } from './components/Hotspots';
import { createGround } from './components/Ground';
import { AnimationClips } from './components/AnimationClips';
import { Outline } from './components/Outline';

const loadingManager = new LoadingManager();
const THREE_PATH = `https://unpkg.com/three@0.${REVISION}.x`;
const dracoLoader = new DRACOLoader(loadingManager).setDecoderPath(`${THREE_PATH}/examples/js/libs/draco/gltf/`);
const ktx2Loader = new KTX2Loader(loadingManager).setTranscoderPath(`${THREE_PATH}/examples/js/libs/basis/`);

export class Viewer {
  constructor(domElement) {
    this.domElement = domElement;

    this.currentObject = null;
    this.scene = new Scene();

    this.camera = createCamera(this.scene);
    this.scene.add(this.camera);

    this.renderer = createRenderer(domElement);

    // Used for rendering dom elements as part of the 3D scene (in our case: hotspots).
    this.labelRenderer = createLabelRenderer(domElement);

    this.controls = new Controls(this.camera, this.domElement);

    this.lights = new Lights(this.camera, this.scene);
    this.lights.addLightsToCamera();

    this.resizer = new Resizer(this.domElement, this.camera, this.renderer, this.labelRenderer);

    this.animate = new Animate(this.camera, this.scene, this.renderer, this.labelRenderer, this.controls.getControls());

    this.animationClips = new AnimationClips(this.animate);

    this.hdrLoader = new HDRLoader(this.renderer, this.scene);

    this.hotspots = new Hotspots(this.camera, this.animate);

    this.outline = new Outline(this.renderer, this.scene, this.camera, this.animate);

    // We need a ground to support casting shadows on a surface
    this.ground = createGround(this.scene);
    this.scene.add(this.ground);

    // A cache for materials, to allow getting back to the baked materials after swapping them dynamically.
    this.materialStorage = {};
  }

  setContent(object, clips) {
    repositionObjectAndCameraToCenter(object, this.camera, this.ground);
    this.controls.setControlsDistance(object);

    // add object to scene
    this.scene.add(object);
    this.currentObject = object;
    this.hotspots.setObject(this.currentObject);

    // clear stored materials
    this.materialStorage = {};

    // set animation clips
    this.animationClips.createMixer(this.currentObject, clips);
  }

  setAnimationClip(clipName) {
    this.animationClips.setActiveClip(clipName);
  }

  playPauseAnimationClip(shouldPlay) {
    this.animationClips.playPauseAnimation(shouldPlay);
  }

  toggleHotspots(show) {
    this.hotspots.toggleHotspots(show);
  }

  clearHotspots() {
    this.hotspots.clearHotspots();
  }

  setHotspots(domElements) {
    this.hotspots.setHotspots(domElements);
  }

  loadHdr(hdr, isSkybox) {
    return this.hdrLoader.loadHdr(hdr, isSkybox);
  }

  setHdrSkybox(isSkybox) {
    return this.hdrLoader.setSceneBackground(isSkybox);
  }

  clearHdr() {
    this.scene.environment = null;
    this.scene.background = null;
  }

  toggleWireframe(isWireframe) {
    traverseObjectMaterials(this.currentObject, (material) => {
      material.wireframe = isWireframe;
    });
  }

  toggleShadows(isShadow) {
    this.currentObject.traverse((o) => {
      if (o.isMesh) {
        o.castShadow = isShadow;
        o.receiveShadow = false;
      }
    });
    this.lights.toggleShadowLight(isShadow);
  }

  resetTextures() {
    this.currentObject.traverse((o) => {
      if (o.isMesh) {
        // retrieve the original texture from our cache, and copy it back to the relevant mesh
        for (const mapType of Object.keys(this.materialStorage)) {
          if (this.materialStorage[mapType].meshName === o.name) {
            o.material[mapType].copy(this.materialStorage[mapType].material);
            o.material[mapType].dispose();
          }
        }
      }
    });
  }

  loadTextures(textures, meshName) {
    const mapTypes = Object.keys(textures);
    return Promise.all(Object.values(textures).map((texture) => loadTexture(texture))).then((textureMaps) => {
      for (let i = 0; i < textureMaps.length; i++) {
        const mapType = mapTypes[i];
        const textureMap = textureMaps[i];
        this.currentObject.traverse((o) => {
          if (o.isMesh && o.name === meshName) {
            textureMap.flipY = false;
            if (!this.materialStorage[mapType]) {
              // Save the original material and the mesh its context to, so we can reuse later when the user
              // Clicks on the default variant
              this.materialStorage[mapType] = {
                material: o.material[mapType]?.clone(),
                meshName,
              };
            }
            o.material[mapType] = textureMap;
            o.material.needsUpdate = true;
          }
        });
      }
    });
  }

  loadModel(url) {
    //remove previous object, since we currently support one
    this.currentObject && this.scene.remove(this.currentObject);

    // clear previous hotspots
    this.currentObject && this.clearHotspots();

    return new Promise((resolve, reject) => {
      const loader = new GLTFLoader()
        .setCrossOrigin('anonymous')
        .setDRACOLoader(dracoLoader)
        .setKTX2Loader(ktx2Loader.detectSupport(this.renderer))
        .setMeshoptDecoder(MeshoptDecoder);
      loader.load(
        url,
        (gltf) => {
          const scene = gltf.scene || gltf.scenes[0];
          const clips = gltf.animations || [];

          if (!scene) {
            reject('Could not detect a scene in the model.');
          }

          this.setContent(scene, clips);
          resolve(extractData(gltf));
        },
        (xhr) => {
          this.domElement.dispatchEvent(
            new CustomEvent('loading', {
              detail: {
                progress: (xhr.loaded / xhr.total) * 100,
              },
            })
          );
        },
        reject
      );
    });
  }

  removeCurrentObjectFromScene() {
    if (this.currentObject) {
      this.scene.remove(this.currentObject);
      this.currentObject = null;
    }
  }

  cleanUp() {
    this.resizer.detachResize();
  }

  outlineMesh(meshName) {
    this.outline.init();
    this.currentObject.traverse((o) => {
      if (o.isMesh && o.name === meshName) {
        this.outline.addOutlineToObj(o);
      }
    });
  }

  zoomIn() {
    this.controls.zoomIn();
  }

  zoomOut() {
    this.controls.zoomOut();
  }
}
