const ANALYTICS_URL = 'https://analytics-api-s.cloudinary.com';

export const EVENTS = {
  VARIANT_CHANGED: {
    name: 'variant-changed',
    action: 'click',
  },
  AR_BUTTON_CLICKED: {
    name: 'ar-button-clicked',
    action: 'click',
  },
  HOTSPOT_CLICKED: {
    name: 'hotspot-clicked',
    action: 'click',
  },
  AR_URL_COPIED: {
    name: 'ar-url-copied',
    action: 'click',
  },
  ZOOM_IN: {
    name: 'zoom-in-clicked',
    action: 'click',
  },
  ZOOM_OUT: {
    name: 'zoom-out-clicked',
    action: 'click',
  },
};

export const createEvents = (elem, isAnalytics) => {
  return (event, label, detail = {}) => {
    const { name, action } = event;
    const eventData = {
      category: 'ar/3d viewer widget',
      event: 'cloudinaryEvent',
      name,
      action,
      label,
      timestamp: new Date().getTime(),
    };

    // Google tag manager (if the user has one configured)
    if (window.dataLayer && isAnalytics) {
      window.dataLayer.push(eventData);
    }
    // Custom event for user to be able to create their own events
    const customEvent = new CustomEvent(name, { detail });
    elem.dispatchEvent(customEvent);

    // CLD events
    sendCloudinaryAnalytics(name, detail);
  };
};

export const sendCloudinaryAnalytics = (event, data) => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  const name = `ar_3d_viewer_${event}`;
  const qs = new URLSearchParams();
  for (const key of Object.keys(data)) {
    qs.append(key, data[key]);
  }

  fetch(`${ANALYTICS_URL}/${name.replace(/\-/gi, '_')}?${qs.toString()}`);
};
