import { PerspectiveCamera } from 'three';

function createCamera() {
  const camera = new PerspectiveCamera(60, 1, 0.1, 1000);

  camera.position.set(0, 0, 10);
  camera.castShadow = true;

  return camera;
}

export { createCamera };
