import { LinearFilter, RGBAFormat, sRGBEncoding, Vector2, WebGLRenderTarget } from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';

const red = 0xff0000;

export class Outline {
  constructor(renderer, scene, camera, animate) {
    this.renderer = renderer;
    this.scene = scene;
    this.camera = camera;
    this.animate = animate;
  }

  init = () => {
    if (this.composer && this.outlinePass) {
      return;
    }

    // Creating a renderTarget to fix an issue where OutlinePass causes the object to appear darker
    // source: https://discourse.threejs.org/t/special-effect-caused-by-outlinepass/25001/6
    const size = this.renderer.getSize(new Vector2());
    const pixelRatio = this.renderer.getPixelRatio();
    const renderTarget = new WebGLRenderTarget(size.width * pixelRatio, size.height * pixelRatio, {
      minFilter: LinearFilter,
      magFilter: LinearFilter,
      format: RGBAFormat,
      encoding: sRGBEncoding,
    });
    renderTarget.texture.name = 'EffectComposer.rt1';
    this.composer = new EffectComposer(this.renderer, renderTarget);
    this.outlinePass = new OutlinePass(new Vector2(size), this.scene, this.camera);

    this.outlinePass.edgeStrength = 10;
    this.outlinePass.edgeThickness = 4;
    this.outlinePass.edgeGlow = 0.1;
    this.outlinePass.visibleEdgeColor.set(red);

    this.renderPass = new RenderPass(this.scene, this.camera);
    this.composer.addPass(this.renderPass);
    this.composer.addPass(this.outlinePass);
    this.animate.addToAnimate('outline', () => {
      this.composer.render();
    });
  };

  addOutlineToObj = (object) => {
    this.outlinePass.selectedObjects = [object];
  };
}
