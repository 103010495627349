// https://dev.to/open-wc/litelement-a-deepdive-into-batched-updates-3hh
// A nice and simple solution for using micro tasks for batching properties update in our web component

export class BatchingElement extends HTMLElement {
  constructor() {
    super();
    /**
     * We initialize an `updateComplete` property with a new
     * Promise that we'll only resolve once `this.update()`
     * has been called
     */
    this.updateComplete = this.__createDeferredPromise();
  }

  async requestUpdate(initial = false) {
    if (!this.updateRequested) {
      this.updateRequested = true;
      this.updateRequested = await false;
      this.update(initial);

      /* When our update is, in fact, complete we resolve the Promise that was assigned to the `updateComplete` property ... */
      this.__resolve();

      /* ... And we assign a new promise to updateComplete for the next update */
      this.updateComplete = this.__createDeferredPromise();
    }
  }

  update() {}

  /**
   * Creates a new deferred promise that we can await,
   * and assign the `resolve` function to `this.__resolve`,
   * so we can resolve the promise after we call `this.update()`
   */
  __createDeferredPromise() {
    return new Promise((resolve) => {
      this.__resolve = resolve;
    });
  }
}
