import { Resize } from '@cloudinary/url-gen/actions/resize';
import { Transformation } from '@cloudinary/url-gen/transformation/Transformation';
import t from '../utils/localization/i18n';

export const getLoaderElement = (cloudinary, publicId) => {
  const img = cloudinary
    .image(publicId)
    .format('gif')
    .quality(40)
    .resize(Resize.scale().width(50).height(50))
    .addTransformation(new Transformation().addAction('fl_animated,z_1'))
    .toURL();

  return `
  <style>
    .loader {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
      
   .loader .image {
        width: 100%;
        height: 100%;
        filter: blur(12px);
        opacity: 0.8;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    
    .loader .image img {
        width: 90%;
    }
  
    .progress-bar-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
    }
    .progress-bar {
        background-color: rgba(241, 241, 241, 0.5);
    }
    
    .progress-bar .progress {
        height: 6px;
        background: black;
        width: 0;
        transition: width 1s ease-in-out;
    }
    
    .progress-bar-container .progress-bar-text {
        font-size: 16px;
        height: 20px;
        font-family: Arial, sans-serif;
        color: black;
        text-align: center;
        margin-bottom: 0.5rem;
    }
  </style>
  <div class="loader">
    <div class="image">
        <img src="${img}" alt="model placeholder" />
    </div>
    <div class="progress-bar-container">
        <div class="progress-bar-text" data-translation="loader.loadingModel">${t('loader.loadingModel')}</div>
      <div class="progress-bar">
          <div class="progress"></div>
      </div>
    </div>
  </div>`;
};
