import { Mesh, PlaneGeometry, ShadowMaterial } from 'three';

function createGround(scene) {
  const geometry = new PlaneGeometry(100, 100);

  const material = new ShadowMaterial();
  material.opacity = 0.1;

  const ground = new Mesh(geometry, material);

  ground.rotation.x = -Math.PI / 2;
  ground.scale.set(100, 100, 100);

  ground.castShadow = false;
  ground.receiveShadow = true;

  return ground;
}

export { createGround };
