import { getLoaderElement } from '../template/loader';

export class Loader {
  constructor(rootElem, viewerElem) {
    this.rootElem = rootElem;
    this.viewerElem = viewerElem;

    this._loaderProgress = this._loaderProgress.bind(this);
  }

  _loaderProgress(e) {
    this.rootElem.querySelector('.progress-bar .progress').style.width = `${e.detail.progress}%`;
  }

  _getSlot() {
    return this.rootElem.querySelector('slot[name="loader"]');
  }

  renderLoader(cloudinary, model) {
    const loaderSlot = this._getSlot();
    loaderSlot.style.display = 'block';
    loaderSlot.innerHTML = getLoaderElement(cloudinary, model);
    this.viewerElem.addEventListener('loading', this._loaderProgress);
  }

  unmountLoader() {
    const loaderSlot = this._getSlot();
    loaderSlot.innerHTML = '';
    loaderSlot.style.display = 'none';
    this.viewerElem.removeEventListener('loading', this._loaderProgress);
  }
}
