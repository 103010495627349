import { ACESFilmicToneMapping, sRGBEncoding, WebGLRenderer, PCFSoftShadowMap } from 'three';
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer.js';

export const createRenderer = (domElement) => {
  const renderer = new WebGLRenderer({ antialias: true, alpha: true });

  renderer.physicallyCorrectLights = true;
  renderer.outputEncoding = sRGBEncoding;
  renderer.gammaFactor = 2.2;
  renderer.toneMapping = ACESFilmicToneMapping;
  renderer.toneMappingExposure = 1;
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = PCFSoftShadowMap;
  renderer.setPixelRatio(window.devicePixelRatio);

  // append the canvas to the root element
  domElement.appendChild(renderer.domElement);

  return renderer;
};

// Hotspots Container
export const createLabelRenderer = (domElement) => {
  const labelRenderer = new CSS2DRenderer();
  labelRenderer.domElement.style.position = 'absolute';
  labelRenderer.domElement.style.top = '0px';
  labelRenderer.domElement.style['pointer-events'] = 'none';
  domElement.appendChild(labelRenderer.domElement);
  return labelRenderer;
};
