import { isDeviceSupportsAr } from '@cld/ar-common';
import { getQrCodeButtonElement, getQrCodeModalElement } from '../template/arqr';
import { EVENTS } from '../utils/analytics';
const QRCode = require('qrcode-svg');

/**
 * AR button will show a modal with QR code on desktop and will initial AR on mobile devices
 * that support this feature
 */

export class ARQR {
  constructor(rootElem, analytics) {
    this.rootElem = rootElem;
    this._arLink = null;
    this._analytics = analytics;
    this._initArElements();
  }

  updateQrCodeModal(url) {
    if (isDeviceSupportsAr) {
      return;
    }
    const qrCode = new QRCode({
      padding: 4,
      content: url,
      width: 256,
      height: 256,
      color: '#000000',
      background: '#ffffff',
      ecl: 'Q',
      join: true,
      swap: true,
    });

    this.rootElem.querySelector('#qr').innerHTML = qrCode.svg();
    this.rootElem.querySelector('#qr-url').innerHTML = `<a href="${url}">${url.substring(0, 20)}...</a>`;
    const linkElem = this.rootElem.querySelector('#qr-url a');
    linkElem.addEventListener('click', async (e) => {
      e.preventDefault();
      this._analytics(EVENTS.AR_URL_COPIED, 'Clicked on copy ar link');
      try {
        await navigator.clipboard.writeText(url);
      } catch (e) {
        // well, if we can't copy to clipboard, nothing to do there..
      }
    });
  }

  displayQrCodeModal(display = true) {
    this.rootElem.querySelector('#qr-code-modal').style.display = display ? 'block' : 'none';
  }

  displayArButton(display = true) {
    const arButtonSlot = this.rootElem.querySelector('slot[name="ar-button"]');
    arButtonSlot.style.display = display ? 'block' : 'none';
  }

  updateArLink(href) {
    this._arLink = href;
  }

  _initArElements() {
    const onClick = (e) => {
      // prevent passing the event to other elements on the scene
      e.stopImmediatePropagation();
      if (!isDeviceSupportsAr) {
        this._analytics(EVENTS.AR_BUTTON_CLICKED, 'Clicked on ar button to open qr modal', { device: 'desktop', mode: 'qr' });
        this.displayQrCodeModal(this.rootElem);
      } else {
        this._analytics(EVENTS.AR_BUTTON_CLICKED, 'Clicked on ar button to open ar app', { device: 'mobile', mode: 'ar' });
        const anchor = document.createElement('a');
        anchor.setAttribute('href', this._arLink);

        // Needed for ios
        anchor.appendChild(document.createElement('img'));
        anchor.rel = 'ar';

        // Click the link
        anchor.click();
      }
    };

    const arButtonSlot = this.rootElem.querySelector('slot[name="ar-button"]');
    arButtonSlot.appendChild(getQrCodeButtonElement().cloneNode(true));
    arButtonSlot.addEventListener('click', onClick);
    arButtonSlot.style.display = 'none';

    // QR Modal - don't mount if on mobile device that supports AR
    if (!isDeviceSupportsAr) {
      this.rootElem.getElementById('qr-code-modal').appendChild(getQrCodeModalElement().cloneNode(true));

      // hide modal on click outside of the qr modal
      this.rootElem.getElementById('qr-close').addEventListener('click', () => {
        this.displayQrCodeModal(false);
      });
    }
  }
}
