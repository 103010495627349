export const createContainerNode = () => {
  const template = document.createElement('template');
  template.innerHTML = `
    <style>
    #container {
        height: 100%;
        width: 100%;
        position: relative;
    }
    
    #container *[slot^="hotspot"]{
        pointer-events: all;
    }
    
    #wrapper, #threejs {
        height: 100%;
        width: 100%;
    }
    
    #wrapper {
        cursor: grab;
    }
    
    #wrapper:active {
        cursor: grabbing;
    }
    
    #wrapper #variants {
        position: absolute;
        left: 30px;
        bottom: 30px;
        display: flex;
    }
    
    #wrapper #controls {
        position: absolute;
        right: 30px;
        bottom: 30px;
        display: flex;
    }

    </style>
    <div id="container">
        <div id="wrapper">
           <div id="threejs"></div> 
           <div id="qr-code-modal"></div>
           <slot name="loader"></slot>
           <div id="variants"></div>
           <div id="controls">
             <slot name="zoom-out"></slot>
             <slot name="zoom-in"></slot>
             <slot name="ar-button"></slot>
           </div>
        </div>
    </div>`;

  return template.content;
};
