import { PMREMGenerator, UnsignedByteType } from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { DebugEnvironment } from 'three/examples/jsm/environments/DebugEnvironment';

class HDRLoader {
  constructor(renderer, scene) {
    this.renderer = renderer;
    this.scene = scene;
    this.envMap = null;
  }

  loadHdr = (hdr, isSkybox) => {
    return new Promise((resolve, reject) => {
      const pmremGenerator = new PMREMGenerator(this.renderer);
      pmremGenerator.compileEquirectangularShader();
      // for basic we use the built in DebugEnvironment to create a programmatically generated simple hdr
      if (hdr === 'basic') {
        const envScene = new DebugEnvironment();
        const generatedCubeRenderTarget = pmremGenerator.fromScene(envScene);
        this.envMap = generatedCubeRenderTarget.texture;
        this.scene.environment = this.envMap;
        if (isSkybox) {
          this.scene.background = this.envMap;
        }
        pmremGenerator.dispose();
        resolve();
      } else {
        new RGBELoader().setDataType(UnsignedByteType).load(
          hdr,
          (texture) => {
            this.envMap = pmremGenerator.fromEquirectangular(texture).texture;
            this.scene.environment = this.envMap;
            this.scene.background = null;
            if (isSkybox) {
              this.scene.background = this.envMap;
            }
            texture.dispose();
            pmremGenerator.dispose();
            resolve();
          },
          (xhr) => {},
          (err) => {
            reject(new Error(err));
          }
        );
      }
    });
  };

  setSceneBackground = (value) => {
    this.scene.background = value ? this.envMap : null;
  };
}

export { HDRLoader };
