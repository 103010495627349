export const getZoomButton = (zoomIn) => {
  const template = document.createElement('template');

  template.innerHTML = `
  <style>
    .zoom {
      border: 0;
      font-size: 20px;
      color: #005CE4;
      background: #FFFFFF;
      cursor: pointer;
      width: 30px;
      padding: 0.75rem 1rem;
      font-weight: bold;
      font-family: Arial, sans-serif;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .zoom-in {
        margin-right: 1rem;
        border-bottom-right-radius: 10rem;
        border-top-right-radius: 10rem;
        box-shadow: 2px 1px 13px -9px rgb(0 0 0 / 75%);
        padding-right: 1.25rem;
    }
    .zoom-out {
        border-bottom-left-radius: 10rem;
        border-top-left-radius: 10rem;
        box-shadow: -4px 1px 13px -9px rgb(0 0 0 / 75%);
        padding-left: 1.25rem;
    }
  
  </style>
  <button class="zoom ${zoomIn ? 'zoom-in' : 'zoom-out'}" aria-label="zoom ${zoomIn ? 'in' : 'out'}">${zoomIn ? '+' : '-'}</button>    
`;

  return template.content;
};
