import { AmbientLight, DirectionalLight, HemisphereLight } from 'three';

export class Lights {
  constructor(camera, scene) {
    this.camera = camera;
    this.scene = scene;
    this.lights = new Map();
    this._defineLights();
  }

  _defineLights() {
    const hemiLight = new HemisphereLight(0xffeeb1, 0x080820, 1);
    hemiLight.name = 'hemisphere';
    this.lights.set(hemiLight.name, hemiLight);

    const ambientLight = new AmbientLight(0xffffff, 1);
    ambientLight.name = 'ambient';
    this.lights.set(ambientLight.name, hemiLight);

    const directionalLightShadow = new DirectionalLight(0xffffff, 3);
    directionalLightShadow.name = 'directional-shadow';
    directionalLightShadow.angle = Math.PI / 5;
    directionalLightShadow.penumbra = 0.3;
    directionalLightShadow.position.set(0, 10, 0);
    directionalLightShadow.shadow.camera.near = 1;
    directionalLightShadow.shadow.camera.far = 30;
    directionalLightShadow.shadow.mapSize.width = 1024;
    directionalLightShadow.shadow.mapSize.height = 1024;
    directionalLightShadow.shadow.camera.right = 15;
    directionalLightShadow.shadow.camera.left = -15;
    this.lights.set(directionalLightShadow.name, directionalLightShadow);

    const directionalLight = new DirectionalLight(0xffffff, 3);
    directionalLight.name = 'directional';
    directionalLight.position.set(0.5, 0, 0.866);
    this.lights.set(directionalLight.name, directionalLight);
  }

  addLightsToCamera() {
    this.scene.add(this.lights.get('hemisphere'));
    this.camera.add(this.lights.get('ambient'));
    this.camera.add(this.lights.get('directional'));
    this.scene.add(this.lights.get('directional-shadow'));
  }

  toggleShadowLight(castShadow) {
    this.lights.get('directional-shadow').castShadow = castShadow;
  }

  getLights() {
    return this.lights;
  }
}
