import { Box3, PropertyBinding, Vector3 } from 'three';

// Get the size of the 3D object and reposition camera and the object itself to the center of the scene
export const repositionObjectAndCameraToCenter = (object, camera, ground) => {
  const box = new Box3().setFromObject(object);
  const size = box.getSize(new Vector3()).length();
  const center = box.getCenter(new Vector3());

  object.position.x += object.position.x - center.x;
  object.position.y += object.position.y - center.y;
  object.position.z += object.position.z - center.z;

  ground.position.y = object.position.y;
  camera.near = size / 100;
  camera.far = size * 100;

  camera.updateProjectionMatrix();

  // set camera position
  camera.position.copy(center);
  camera.position.z += size;
  camera.lookAt(center);
};

// Traverse all parts of a 3D object and call a callback function for each material
export const traverseObjectMaterials = (object, callback) => {
  object.traverse((node) => {
    if (node.isMesh) {
      const materials = Array.isArray(node.material) ? node.material : [node.material];
      materials.forEach(callback);
    }
  });
};

// This function replicated the naming convention of Three.js.
// If the mesh is a single primitive, return the node name or fall back to a basic name.
// If there are multiple primitives, sanitize the mesh name and add a counter
const getMeshName = ({ meshName, nodeName, index, isSingle }) => {
  if (isSingle) {
    return PropertyBinding.sanitizeNodeName(nodeName || meshName || `mesh_${index}`);
  } else if (meshName) {
    const threeSanitizedName = PropertyBinding.sanitizeNodeName(meshName);
    return `${threeSanitizedName}${index > 0 ? '_' + index : ''}`;
  }
  return `mesh_${index}`;
};

const extractTextureSize = (objectJson) => {
  const { images, bufferViews } = objectJson;
  return (
    images?.reduce((acc, image) => {
      const bufferIndex = image.bufferView;
      const bufferView = bufferViews[bufferIndex];
      return acc + bufferView.byteLength;
    }, 0) || 0
  );
};
const extractMeshDataAndTextures = (objectJson) => {
  const { nodes, meshes, textures, images, materials } = objectJson;
  return nodes.reduce((acc, currNode) => {
    if (typeof currNode.mesh !== 'undefined') {
      const meshName = currNode.name;
      const meshIndex = currNode.mesh;
      let map; // diffuse map / base color map (currently this is the only one we need).

      const primitives = meshes[meshIndex].primitives;
      primitives.forEach((primitive, index) => {
        const materialIndex = primitive.material;
        const diffuseMapIndex = materials[materialIndex]?.pbrMetallicRoughness?.baseColorTexture?.index;
        map =
          diffuseMapIndex !== undefined
            ? {
                index: diffuseMapIndex,
                name: textures?.[diffuseMapIndex]?.name || images?.[diffuseMapIndex]?.name || materials[materialIndex]?.name,
              }
            : null;

        const name = getMeshName({ meshName: meshes[meshIndex].name, nodeName: meshName, index, isSingle: primitives.length === 1 });
        const parentName = primitives.length > 1 ? meshName : undefined;
        acc.push({ parentName, name, textures: { map } });
      });
    }
    return acc;
  }, []);
};

// Get some data we want to display or use from a 3D object
export const extractData = (object) => {
  let trianglesCount = 0;
  let meshCount = 0;
  let generator = object.asset.generator;
  object.scene.traverse(function (child) {
    if (child.isMesh) {
      meshCount++;
      if (Number.isInteger(child.geometry.index?.count)) {
        trianglesCount += child.geometry.index.count / 3;
      } else if (Number.isInteger(child.geometry.attributes?.position?.count)) {
        trianglesCount += child.geometry.attributes.position.count / 3;
      }
    }
  });

  // Get an array of meshes and their textures name and index. This data is used for the custom action
  // to let customers create variants that replace the texture files
  const meshData = extractMeshDataAndTextures(object.parser.json);
  const textureSize = extractTextureSize(object.parser.json);

  return { trianglesCount, meshCount, generator, meshData, textureSize };
};
