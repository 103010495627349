import { Cloudinary } from '@cloudinary/url-gen';
import { loadModelConfigurationFromContext, mobileArLinkGenerator } from '@cld/ar-common';
import { Viewer } from '../core/Viewer';
import { createContainerNode } from './template/container';
import { Store } from './store/store';
import { BatchingElement } from './utils/BatchingElement';
import { storeDiff, isTrue, generateQrUrl } from './utils/helpers';
import { HotspotsObserver } from './slots/Hotspots';
import { Loader } from './slots/Loader';
import { ARQR } from './slots/ARQR';
import { Variants } from './slots/Variants';
import { Zoom } from './slots/Zoom';
import { prepareTextures } from './slots/Variants.helpers';
import { Resize } from './utils/resize';
import { createEvents, sendCloudinaryAnalytics } from './utils/analytics';
import t, { getLanguage, addValues, setLanguage } from './utils/localization/i18n';

export class Ar3DViewerComponent extends BatchingElement {
  constructor() {
    super();
    this._mounted = false;
    this.attachShadow({ mode: 'open' });
    this._analytics = null;

    // Attach root container
    this.shadowRoot.appendChild(createContainerNode().cloneNode(true));
  }

  static get observedAttributes() {
    return ['cloud', 'models', 'hdr', 'skybox', 'wireframe', 'ar', 'shadow', 'placement', 'animation-name', 'animation-play'];
  }

  _loadHdr() {
    const hdr = this._state.getValue('hdr');
    if (hdr) {
      const hdrUrl = hdr === 'basic' ? hdr : this._cloudinary.image(hdr).setAssetType('raw').toURL();
      this._viewer.loadHdr(hdrUrl, isTrue(this._state.getValue('skybox')));
    } else {
      this._viewer.clearHdr();
    }
  }

  _render() {
    const cloudName = this._cloudinary.getConfig().cloud.cloudName;
    const models = this._state.getValue('models').split(';');
    const model = models[0];
    const shouldLoadVariants = isTrue(this._state.getValue('variants'));

    this._viewer.removeCurrentObjectFromScene(); // remove previous model if exists
    this._loader.renderLoader(this._cloudinary, model);

    this._variants.clearVariants();

    this._arQr.displayArButton(false);
    this._arQr.displayQrCodeModal(false);
    this._zoom.displayZoomControls(false);

    let isOptimize = isTrue(this._state.getValue('optimize'));
    let qualityValue = this._state.getValue('quality');

    let tempVariants = [];
    loadModelConfigurationFromContext(cloudName, model, shouldLoadVariants)
      .then((data) => {
        const { optimize, quality, variants } = data;
        if (typeof optimize === 'boolean') {
          isOptimize = optimize;
        }
        if (typeof quality === 'number') {
          qualityValue = quality;
        }
        tempVariants = variants;
      })
      .finally(() => {
        const cldAsset = this._cloudinary.image(model).format('glb');
        isOptimize && cldAsset.addTransformation(`e_decimate:${qualityValue}`);
        this._viewer
          .loadModel(cldAsset.toURL())
          .then((data) => {
            this.dispatchEvent(
              new CustomEvent('loaded', {
                detail: data,
              })
            );
            this._loader.unmountLoader();
            this._viewer.toggleWireframe(isTrue(this._state.getValue('wireframe')));
            this._viewer.toggleShadows(isTrue(this._state.getValue('shadow')));
            this._hotspots.setHotspots();
            this._variants.addVariants(this._cloudinary, tempVariants);

            // Play animation if autoplay=true
            this._viewer.setAnimationClip(this._state.getValue('animation-name'));
            this._viewer.playPauseAnimationClip(isTrue(this._state.getValue('animation-play')));

            // update ar/qr links
            this._updateArQrLinks();
            // show qr/ar button if ar=true
            this._arQr.displayArButton(isTrue(this._state.getValue('ar')));
            // show zoom controls
            this._zoom.displayZoomControls(true);
          })
          .catch(() => {
            this.dispatchEvent(new CustomEvent('loading-failed'));
          });
      });
  }

  _updateArQrLinks = () => {
    const cloudName = this._cloudinary.getConfig().cloud.cloudName;
    const models = this._state.getValue('models').split(';');
    const placement = this._state.getValue('placement');
    const optimize = this._state.getValue('optimize');
    const quality = this._state.getValue('quality');
    const publicId = models[0];
    const variant = this._variants.getActiveVariantData();
    const locale = getLanguage();

    const qrUrl = generateQrUrl({ cloudName, publicId, placement, variantId: variant?.id, optimize, quality, locale });
    const arUrl = mobileArLinkGenerator({ cloudName, publicId, variant, fallbackUrl: window.location.href, placement, optimize, quality });

    this._arQr.updateQrCodeModal(qrUrl);
    this._arQr.updateArLink(arUrl);
  };

  connectedCallback() {
    this._prevState = new Store();
    // The first update cycle (on mount) receives all data from the dom element, since attributeChangedCallback is called before
    // Thus ending up with data we can't used since the dom is not rendered yet... So we skip the first attributeChangedCallback call,
    // and do this instead.
    this._state = new Store(
      this.getAttributeNames().reduce((acc, currAttrName) => {
        return { [currAttrName]: this.getAttribute(currAttrName), ...acc };
      }, {})
    );
    this._analytics = createEvents(this, isTrue(this._state.getValue('analytics')));
    sendCloudinaryAnalytics('init', this._state.getStore()); // this event is only relevant internally
    const threeContainer = this.shadowRoot.getElementById('threejs');
    this._viewer = new Viewer(threeContainer);
    this._loader = new Loader(this.shadowRoot, threeContainer);
    this._arQr = new ARQR(this.shadowRoot, this._analytics);
    this._hotspots = new HotspotsObserver(this, this._viewer, this._analytics);
    this._zoom = new Zoom(this.shadowRoot, this._viewer, this._analytics);
    this._variants = new Variants(this.shadowRoot, this._viewer, this._analytics, () => {
      this._updateArQrLinks();
    });
    this._resize = new Resize(this.shadowRoot);
    this._mounted = true;
    this.requestUpdate(true);
  }

  disconnectedCallback() {
    this._viewer.cleanUp();
    this._resize.detachResize();
    this._hotspots.clear();
  }

  update(initial) {
    // Nothing to diff on the first update cycle, so just use the store itself.
    const dataToUpdate = initial ? this._state.getStore() : storeDiff(this._state.getStore(), this._prevState.getStore());

    // should be before render (turns ar off on load). in case the user toggle ar dynamically
    if ('ar' in dataToUpdate) {
      this._arQr.displayArButton(isTrue(dataToUpdate.ar));
    }

    if (dataToUpdate.cloud) {
      this._cloudinary = new Cloudinary({
        cloud: {
          cloudName: dataToUpdate.cloud,
        },
      });
    }

    if (dataToUpdate.models) {
      this._render();
    }

    // Prevent updating aspects of the 3d scene if there is no model in the store
    if (this._state.getValue('cloud') && this._state.getValue('models')) {
      if ('hdr' in dataToUpdate) {
        this._loadHdr();
      } else if (dataToUpdate.skybox) {
        this._viewer.setHdrSkybox(isTrue(dataToUpdate.skybox));
      }

      if (!dataToUpdate.models && 'wireframe' in dataToUpdate) {
        this._viewer.toggleWireframe(isTrue(dataToUpdate.wireframe));
      }

      if (!dataToUpdate.models && 'shadow' in dataToUpdate) {
        this._viewer.toggleShadows(isTrue(dataToUpdate.shadow));
      }

      if (!dataToUpdate.models && 'animation-play' in dataToUpdate) {
        this._viewer.playPauseAnimationClip(isTrue(dataToUpdate['animation-play']));
      }

      if (!dataToUpdate.models && 'animation-name' in dataToUpdate) {
        this._viewer.setAnimationClip(dataToUpdate['animation-name']);
        this._viewer.playPauseAnimationClip(isTrue(dataToUpdate['animation-play'] || this._state.getValue('animation-play')));
      }
    }

    // update prevState to current data
    this._prevState.mergeValues(this._state.getStore());
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (!this._mounted || oldValue === newValue) {
      return;
    }
    this._state.update(name, newValue);
    this.requestUpdate();
  }

  loadTextures(textures, meshName) {
    const normalizedTextures = prepareTextures(textures, this._cloudinary);
    this._viewer
      .loadTextures(normalizedTextures, meshName)
      .then(() => {
        this.dispatchEvent(new CustomEvent('loaded-textures'));
      })
      .catch((e) => {
        this.dispatchEvent(
          new CustomEvent('loading-textures-failed', {
            detail: e,
          })
        );
      });
  }

  resetTextures() {
    this._viewer.resetTextures();
  }

  outlineMesh(meshName) {
    this._viewer.outlineMesh(meshName);
  }

  setVariants = (variants) => {
    this._variants.clearVariants();
    this._variants.addVariants(this._cloudinary, variants);

    // regenerate qr code with variant
    this._updateArQrLinks();
  };

  loadLanguages = (dicts) => {
    addValues(dicts);
  };

  changeLanguage = (lang) => {
    setLanguage(lang);

    // Update all elements that are bound to a translation string
    const translatedElements = this.shadowRoot.querySelectorAll('[data-translation]');
    for (const elem of translatedElements) {
      const translationKey = elem.getAttribute('data-translation');
      elem.innerHTML = t(translationKey);
    }

    // regenerate qr code with locale in url
    this._updateArQrLinks();
  };
}
