import throttle from '../../misc/throttle';

const MLS = 16; // 1000ms / 60fps ≈ 16 milliseconds per frame

const setSize = (container, camera, renderer, labelRenderer) => {
  camera.aspect = container.clientWidth / container.clientHeight;
  camera.updateProjectionMatrix();

  renderer.setSize(container.clientWidth, container.clientHeight);
  labelRenderer.setSize(container.clientWidth, container.clientHeight);
};

export class Resizer {
  constructor(container, camera, renderer, labelRenderer) {
    // set initial size
    setSize(container, camera, renderer, labelRenderer);

    const onResize = () => {
      // set the size again if a resize occurs
      setSize(container, camera, renderer, labelRenderer);
      // perform any custom actions
      this.onResize();
    };
    this.throttledonResize = throttle(onResize, MLS);

    window.addEventListener('resize', this.throttledonResize);
  }

  onResize() {}

  detachResize = () => {
    window.removeEventListener('resize', this.throttledonResize);
  };
}
