export default {
  en: {
    translation: {
      arQr: {
        viewInAr: 'View in AR',
        viewInSpace: 'View in your own space',
        inAr: 'in augmented reality',
        scanQrCode: 'Scan this qr code:',
        clickToCopy: 'Or click to copy this url:',
      },
      loader: {
        loadingModel: 'Loading 3D Model...',
      },
    },
  },
};
