// This class is used for being able to spots on the fly append of hotspot slots to the web component

import { EVENTS } from '../utils/analytics';

export class HotspotsObserver {
  constructor(root, viewer, analytics) {
    this._root = root;
    this._viewer = viewer;
    this._analytics = analytics;
    this._observer = new MutationObserver((mutations) => {
      const hasChangedHotspots = mutations.some((mutation) => {
        //Detect <img> insertion
        for (const addedNode of mutation.addedNodes) {
          if (typeof addedNode.getAttribute === 'function' && addedNode.getAttribute('slot').startsWith('hotspot')) {
            return true;
          }
        }

        for (const removedNodes of mutation.removedNodes) {
          if (typeof removedNodes.getAttribute === 'function' && removedNodes.getAttribute('slot').startsWith('hotspot')) {
            return true;
          }
        }

        return false;
      });

      if (hasChangedHotspots) {
        this.setHotspots();
      }
    });

    this._observer.observe(root, { childList: true });
  }

  setHotspots = () => {
    this._root.querySelectorAll('[slot^="hotspot"]').forEach((element) => {
      element.addEventListener('click', () => {
        const slot = element.getAttribute('slot');
        this._analytics(EVENTS.HOTSPOT_CLICKED, `Clicked on hotspot ${slot}`, { id: slot });
      });
    });
    this._viewer.setHotspots(this._root.querySelectorAll('[slot^="hotspot"]'));
  };

  clear() {
    this._observer.disconnect();
  }
}
