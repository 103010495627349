import { MIN_HEIGHT, MIN_WIDTH } from './consts';

export const storeDiff = (newStore, oldStore) => {
  return Object.keys(newStore).reduce((acc, currKey) => {
    if (newStore[currKey] !== oldStore[currKey]) {
      acc[currKey] = newStore[currKey];
    }
    return acc;
  }, {});
};

export const isTrue = (str) => str === 'true';

export const generateQrUrl = ({ cloudName, publicId, placement, variantId, optimize, quality, locale }) => {
  const cldEnv = window.location.hostname.match(/(.*)\-(staging(\d)?|nightly)\.cloudinary\.com/)?.[2];
  const env = cldEnv || localStorage.getItem('env');
  const urlBase = `https://ar-splash${env ? `-${env}` : ''}.cloudinary.com/`;
  const params = new URLSearchParams();
  params.append('cloudName', cloudName);
  params.append('publicId', publicId);
  params.append('placement', placement);
  variantId && params.append('variantId', variantId);
  params.append('optimize', optimize);
  params.append('quality', quality);
  params.append('locale', locale);

  return `${urlBase}?${params.toString()}`;
};

export const getContainerSizeDeclaration = (rootElement) => {
  const containerElement = rootElement.querySelector('#container');
  let width = containerElement.offsetWidth;
  let height = containerElement.offsetHeight;
  let size = 'sm';
  if (height >= MIN_HEIGHT && width >= MIN_WIDTH) {
    size = 'lg';
  } else if (width >= MIN_WIDTH) {
    size = 'md';
  }

  return size;
};
