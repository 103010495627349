import { getZoomButton } from '../template/zoom';
import { EVENTS } from '../utils/analytics';

const ZOOM_IN = true;
const ZOOM_OUT = false;

export class Zoom {
  constructor(rootElem, viewer, analytics) {
    this._rootElem = rootElem;
    this._viewer = viewer;
    this._analytics = analytics;
    this._initZoomControls();
  }

  _initZoomControls() {
    const zoomInButton = this._rootElem.querySelector('slot[name="zoom-in"]');
    zoomInButton.appendChild(getZoomButton(ZOOM_IN).cloneNode(true));
    zoomInButton.addEventListener('click', () => {
      this._viewer.zoomIn();
      this._analytics(EVENTS.ZOOM_IN, 'Clicked on zoom in');
    });

    const zoomOutButton = this._rootElem.querySelector('slot[name="zoom-out"]');
    zoomOutButton.appendChild(getZoomButton(ZOOM_OUT).cloneNode(true));
    zoomOutButton.addEventListener('click', () => {
      this._viewer.zoomOut();
      this._analytics(EVENTS.ZOOM_OUT, 'Clicked on zoom out');
    });
  }

  displayZoomControls(display = true) {
    this._rootElem.querySelectorAll('slot[name^="zoom"]').forEach((elem) => {
      elem.style.display = display ? 'block' : 'none';
    });
  }
}
