import t from '../utils/localization/i18n';

const arIcon =
  // eslint-disable-next-line max-len
  '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M18.25 7.6l-5.5-3.18c-.46-.27-1.04-.27-1.5 0L5.75 7.6c-.46.27-.75.76-.75 1.3v6.35c0 .54.29 1.03.75 1.3l5.5 3.18c.46.27 1.04.27 1.5 0l5.5-3.18c.46-.27.75-.76.75-1.3V8.9c0-.54-.29-1.03-.75-1.3zM7 14.96v-4.62l4 2.32v4.61l-4-2.31zm5-4.03L8 8.61l4-2.31 4 2.31-4 2.32zm1 6.34v-4.61l4-2.32v4.62l-4 2.31zM7 2H3.5C2.67 2 2 2.67 2 3.5V7h2V4h3V2zm10 0h3.5c.83 0 1.5.67 1.5 1.5V7h-2V4h-3V2zM7 22H3.5c-.83 0-1.5-.67-1.5-1.5V17h2v3h3v2zm10 0h3.5c.83 0 1.5-.67 1.5-1.5V17h-2v3h-3v2z"/></svg>';

const closeIcon =
  // eslint-disable-next-line max-len
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid"><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12"></path></svg>';

const graphicSrc = 'https://res.cloudinary.com/prod/image/upload/v1635857842/widgets/3dar/view_in_ar.svg';

const cldLabsLogo = 'https://product-assets-res.cloudinary.com/image/upload/v1640002864/ar3d/cloudinary_labs_logo_white.svg';

const QR_BUTTON_TEMPLATE = `
    <style>
    .ar-button {
      color: #fff;
      border: 0px;
      font-size: 14px;
      background: #005CE4;
      cursor: pointer;
      padding: 0.75rem 1.25rem;
      border-radius: 10rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-family: Arial, sans-serif;
      box-shadow: -3px 8px 24px -10px rgba(0,0,0,0.75);
      -webkit-box-shadow: -3px 8px 24px -10px rgba(0,0,0,0.75);
      -moz-box-shadow: -3px 8px 24px -10px rgba(0,0,0,0.75);
    }
    
    .ar-button svg {
        margin-right: 0.25rem;
    }
    
    .ar-button path {
        fill: #fff;
    }

    </style>
    <button class="ar-button" alt="Get QR code to view in AR">
                ${arIcon}
                <span data-translation="arQr.viewInAr">${t('arQr.viewInAr')}</span>
            </button>`;

const QR_MODAL_TEMPLATE = `
    <style>
    #qr-code-modal {
      position: absolute;
      z-index: 100;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.7);
      backdrop-filter: blur(10px);
      cursor: default;
      width: 100%;
      height: 100%;
      font-family: Arial, sans-serif;
      display: none;  
    }
    
    .qr-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    
    .qr-data-section a {
        color: rgb(142, 232, 255);
    }

    .qr-data-section {
        color: white;
        font-size: 24px;
        display: flex;
        flex-direction: column;
        padding-top: 3rem;
    }
    
    .qr-data-section * {
        margin: 10px 0;
     }
    
    .qr-data-container {
        display: flex;
        justify-content: space-evenly;
        height: 70%;
    }
    
    .qr-cld-labs-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
    } 
       
    .qr-cld-labs-logo img {
        width: 200px;
        height: auto;
    }
    
    .qr-info {
        display: flex;
        color: white;
        font-size: 35px;
        height: 30%;
        width: 70%;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }
    
    .qr-info img {
        height: 100px;
    }
    
    .qr-info p {
        margin: 0.25rem 1.75rem;
    }
    
    .qr-container-content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        height: 100%;
    }
    
    #qr-close {
        background: transparent;
        border: 0;
        width: 48px;
        height: 48px;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
    }
    
    #qr-close path {
        fill: #fff;
    }
    
    [data-size="md"] .qr-container-content {
        flex-direction: row;
        align-items: center;
    }    
    
    [data-size="md"] .qr-data-container {
        height: 100%;
        width: 60%;
    }  
    
    [data-size="sm"] .qr-data-container {
        height: 100%;
    }       
    
    [data-size="sm"] .qr-data-container,
    [data-size="md"] .qr-data-container {
        display: flex;
        flex-direction: column;
    }   
     
    [data-size="sm"] .qr-data-section,
    [data-size="md"] .qr-data-section {
        padding-top: 0;
    }     
    
    [data-size="md"] .qr-info {
        padding-left: 1rem;
    }
    
    [data-size="sm"] .qr-info,
    [data-size="sm"] .qr-data-section.copy-ar,
    [data-size="md"] .qr-data-section.copy-ar {
        display: none;
    }   

    </style>
    <div class="qr-container">
      <button alt="Close QR modal" id="qr-close">
          ${closeIcon}
      </button>
      <div class="qr-container-content">
      <div class="qr-info">
        <img src="${graphicSrc}" alt="" role="presentation" />
        <div>
            <p>
              <strong data-translation="arQr.viewInSpace">${t('arQr.viewInSpace')}</strong> 
              <span data-translation="arQr.inAr">${t('arQr.inAr')}</span>
            </p>
        </div>
     </div>
      <div class="qr-data-container">
        <div class="qr-data-section scan-qr"> 
          <div data-translation="arQr.scanQrCode">${t('arQr.scanQrCode')}</div>
          <div id="qr">
          </div>
        </div>
        <div class="qr-data-section copy-ar">
          <div data-translation="arQr.clickToCopy">${t('arQr.clickToCopy')}</div>
          <div id="qr-url">
          </div>
        </div>
      </div>
      </div>
      <div class="qr-cld-labs-logo">
        <img src="${cldLabsLogo}" alt="Powered by Cloudinary Labs logo" />
      </div>
    </div>
`;

export const getQrCodeButtonElement = () => {
  const template = document.createElement('template');
  template.innerHTML = QR_BUTTON_TEMPLATE;

  return template.content;
};

export const getQrCodeModalElement = () => {
  const template = document.createElement('template');
  template.innerHTML = QR_MODAL_TEMPLATE;

  return template.content;
};
