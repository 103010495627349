export const getVariantWrapperElement = () => {
  const template = document.createElement('template');
  template.innerHTML = `
      <style>
          #variants-wrapper {
              display: flex;
          }

          #variants-wrapper button.variant {
            border: 2px solid #fff;
            border-radius: 40px;
            width: 26px;
            height: 26px;
            margin-right: 0.5rem;
            cursor: pointer;
            box-shadow: 0 0 0 2px transparent;
            transition: border, box-shadow 0.3s ease-in-out;
          }

          #variants-wrapper button.variant[data-active="true"] {
            border-color: #fff;
            box-shadow: 0 0 0 2px #000;
          }
          
          #variants-wrapper .tooltip {
              position: relative;
           }

          #variants-wrapper .tooltip .tooltip-text {
            visibility: hidden;
            background-color: rgba(0,0,0,0.75);
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 0.25rem;
            position: absolute;
            z-index: 1;
            bottom: 100%;
            left: 50%;
            margin-left: -0.25rem;
            transform: translate(-50%, -50%);
            font-size: 12px;
          }
          
          #variants-wrapper .tooltip:hover .tooltip-text {
            visibility: visible;
          }
      </style>
      <div id="variants-wrapper">
      </div>
    `;

  return template.content;
};

export const getVariantElement = (name, color, colorB) => {
  const className = name.replace(/\s+|\W+/gi, '').toLowerCase();
  const template = document.createElement('template');
  template.innerHTML = `
      <style>
          .variant.variant-${className} {
              background: linear-gradient( -45deg, ${color} 48%, ${colorB || color} 48% ); 
              border-radius: 50%;
          }
      </style>
      <div class="tooltip">
        <button class="variant variant-${className}" alt="Display 3d item variant ${name}"></button>
        <span class="tooltip-text">${name}</span>
      </div>
    `;

  return template.content;
};
