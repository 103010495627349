import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';

export class Hotspots {
  constructor(camera, animate) {
    this.camera = camera;
    this.animate = animate;
    this.hotspotObjects = [];

    this.animate.addToAnimate('hotspots', this.updateHotspotsOpacity.bind(this));
  }

  setObject(object) {
    this.object = object;
  }

  // When the hotspots appear behind the model, we want to make them fade and be un-clickable.
  updateHotspotsOpacity() {
    for (const hotspotObject of this.hotspotObjects) {
      const meshDistance = this.camera.position.distanceTo(this.object.position);
      const spriteDistance = this.camera.position.distanceTo(hotspotObject.position);
      const spriteBehindObject = spriteDistance > meshDistance;
      hotspotObject.element.style.opacity = spriteBehindObject ? 0.25 : 1;
      hotspotObject.element.style['pointer-events'] = spriteBehindObject ? 'none' : 'all';
    }
  }

  setHotspots(domElements) {
    if (!domElements) {
      return;
    }

    // clear prev hotspots
    this.clearHotspots();

    // set new hotspots
    for (let domElem of domElements) {
      // using custom slots to allow access to out of shadow dom styles
      const div = document.createElement('div');
      const slot = document.createElement('slot');
      slot.name = domElem.getAttribute('slot');
      slot.appendChild(domElem.cloneNode(true));
      div.appendChild(slot);
      const domElemObject = new CSS2DObject(div);

      // A trick to keep the click event from provided buttons
      domElemObject.element.addEventListener('pointerdown', () => {
        domElem.click();
      });

      // Get position from data-position attr
      const positionData = domElem.dataset.position?.split(' ');
      domElemObject.position.set(parseFloat(positionData?.[0] ?? 0), parseFloat(positionData?.[1] ?? 0), parseFloat(positionData?.[2] ?? 0));

      this.object.add(domElemObject);
      this.hotspotObjects.push(domElemObject);
    }
  }

  clearHotspots() {
    for (let hotspotObject of this.hotspotObjects) {
      this.object.remove(hotspotObject);
    }
    this.hotspotObjects = [];
  }

  toggleHotspots(show) {
    for (let hotspotObject of this.hotspotObjects) {
      show ? this.object.add(hotspotObject) : this.object.remove(hotspotObject);
    }
  }
}
