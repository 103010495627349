class Animate {
  constructor(camera, scene, renderer, labelRenderer, controls) {
    this.camera = camera;
    this.scene = scene;
    this.renderer = renderer;
    this.labelRenderer = labelRenderer;
    this.controls = controls;
    this.prevTime = 0;
    this.animateFuncs = {};

    requestAnimationFrame(this.animate);
  }

  addToAnimate(id, func) {
    this.animateFuncs[id] = func;
  }

  removeFromAnimate(id) {
    delete this.animateFuncs[id];
  }

  animate = (time) => {
    const timeDelta = (time - this.prevTime) / 1000;
    requestAnimationFrame(this.animate);

    this.controls.update();
    this.renderer.render(this.scene, this.camera);
    this.labelRenderer.render(this.scene, this.camera);
    for (const func of Object.values(this.animateFuncs)) {
      func?.(timeDelta);
    }
    this.prevTime = time;
  };
}

export { Animate };
