export class Store {
  constructor(initial) {
    this._store = {
      cloud: null,
      models: '',
      hdr: null,
      wireframe: 'false',
      variants: 'false',
      ar: 'true',
      shadow: 'false',
      optimize: 'false',
      quality: '80',
      placement: 'floor',
      analytics: 'false',
      'animation-name': null,
      'animation-play': 'false',
      ...initial,
    };
  }

  update(key, value) {
    this._store[key] = value;
  }

  getValue(key) {
    return this._store[key];
  }

  getStore() {
    return this._store;
  }

  mergeValues(values) {
    this._store = { ...this._store, ...values };
  }
}
