import { AnimationMixer } from 'three';
import { warn } from '../../misc/log';

export class AnimationClips {
  constructor(animate) {
    this.animate = animate;
    this.mixer = null;
    this.clips = [];
    this.activeClip = null;
  }

  createMixer = (object, clips) => {
    this.clips = clips;
    if (this.mixer) {
      this.animate.removeFromAnimate('mixer');
      this.mixer.stopAllAction();
      this.mixer.uncacheRoot(this.mixer.getRoot());
      this.mixer = null;
    }
    if (this.clips?.length) {
      this.mixer = new AnimationMixer(object);
      this.animate.addToAnimate('mixer', (time) => {
        this.mixer.update(time);
      });
    }
  };

  getClipByName = (name) => {
    if (!name) {
      return undefined;
    }
    const clip = this.clips.find((clip) => clip.name === name);
    if (!clip) {
      warn(`Could not detect an animation with name: ${name}`);
    }
    return clip;
  };

  setActiveClip = (clipName) => {
    if (!this.mixer || !this.clips?.length) {
      return;
    }

    this.mixer.stopAllAction();
    const clipByName = this.getClipByName(clipName);
    // If could not find a clip by name, revert to the first clip the object has.
    const action = this.mixer.clipAction(clipByName || this.clips[0]);
    action.reset();
    action.timeScale = 1;
    action.clampWhenFinished = true;
    this.activeClip = action;
  };

  playPauseAnimation = (shouldPlay) => {
    if (!this.mixer || !this.clips?.length) {
      return;
    }
    shouldPlay ? this.activeClip.play() : this.mixer.stopAllAction();
  };
}
