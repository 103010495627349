import { createInstance } from 'i18next';
import defaultTranslations from './default.translations';

const DEFAULT_NS = 'translation';

let i18nInstance;

const init = () => {
  i18nInstance = createInstance();

  i18nInstance.init({
    lng: 'en',
    fallbackLng: 'en',
    resources: defaultTranslations,
    defaultNS: DEFAULT_NS,
  });
};

init();

export default (key, options) => i18nInstance.t(key, options);

const getLanguage = () => i18nInstance.language;

/**
 * add resources in a nested structure starting from the language
 *
 * @param dicts should be object map of the following shape:
 *          {
 *              en: {...},
 *              de: {...},
 *              ...
 *          }
 *  should include at least one language as the first level name
 */

const addValues = (dicts) => {
  Object.keys(dicts).forEach((lang) => {
    i18nInstance.addResourceBundle(lang, DEFAULT_NS, dicts[lang], true, true);
  });
};

const changeLanguage = (lang) => i18nInstance.changeLanguage(lang);

const setLanguage = (lang) => {
  if (!~i18nInstance.languages[lang]) {
    i18nInstance.loadLanguages([lang], (err) => {
      if (err) {
        console.error(`[i18n]: failed to load language: ${lang}`);
      } else {
        changeLanguage(lang);
      }
    });
  } else {
    changeLanguage(lang);
  }
};

export { getLanguage, addValues, setLanguage };
